.App {
  text-align: center;
}

.App-header {
  background-color: white;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: 5rem;
  font-family: 'Nunito', sans-serif;
  letter-spacing: -5px;
  color: black;
}

#logo {
  height: 8.5rem;
  margin: 0;
}

.App-header p {
  margin-top: 0;
}

.App-link {
  color: #61dafb;
}

@media (max-width: 600px) {
  #logo {
    height: 6rem;
    margin: 0;
  }

  .App-header {
    font-size: 3.5rem;
    letter-spacing: -3.25px;
  }
}

@media (max-width: 350px) {
  #logo {
    height: 3.5rem;
    margin: 0;
  }

  .App-header {
    font-size: 2.5rem;
    letter-spacing: -2px;
  }
}
